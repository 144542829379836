import httpClient from '../utils/axios'
import {
  handleServiceResponse,
  handleServiceError,
} from '../utils/serviceResponseHelper'

const getDocumentTemplates = async () => {
  return httpClient
    .get(`${window.config.DOCUMENT_API_URL}/v1/documenttemplate/getall`)
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const getDocumentTemplate = async id => {
  return httpClient
    .get(
      `${window.config.DOCUMENT_API_URL}/v1/documenttemplate/get?documentId=${id}`
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const addDocumentTemplate = async newTemplate => {
  return httpClient
    .post(
      `${window.config.DOCUMENT_API_URL}/v1/documenttemplate/create`,
      newTemplate
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const updateDocumentTemplate = async updateTemplate => {
  return httpClient
    .put(
      `${window.config.DOCUMENT_API_URL}/v1/documenttemplate/update`,
      updateTemplate
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

export default {
  getDocumentTemplates,
  getDocumentTemplate,
  addDocumentTemplate,
  updateDocumentTemplate,
}
