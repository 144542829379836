import React, { useState, useEffect } from 'react'

import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from 'draft-js'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { makeStyles } from '@material-ui/core/styles'

import AdminCard from './adminCard'
import AdminDocumentPreview from './adminDocumentPreview'
import CustomEditorDropdown from './customEditorDropdown'
import ActionBar from './actionBar'

const useStyles = makeStyles({
  formControl: { marginBottom: '20px', width: 200 },
  textbox: { minHeight: 260 },
})

const DocumentTemplateForm = ({
  initialFormData,
  currentTemplates,
  brandings,
  editables,
  onSubmit,
}) => {
  const classes = useStyles()

  const [isEditMode, setIsEditMode] = useState(true)

  const [name, setName] = useState('')
  const [nameIsValid, setNameIsValid] = useState(false)
  const [nameValidationMessage, setNameValidationMessage] = useState('')

  const [brandingId, setBrandingId] = useState(0)
  const [branding, setBranding] = useState({})
  const [brandingIsValid, setBrandingIsValid] = useState(false)
  const [brandingValidationMessage, setBrandingValidationMessage] = useState('')

  useEffect(() => {
    setBranding(brandings.find(x => x.id === brandingId))
  }, [brandings, brandingId])

  const [isArchived, setIsArchived] = useState(false)
  const [isPublished, setIsPublished] = useState(false)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [editorIsValid, setEditorIsValid] = useState(false)
  const [editorValidationMessage, setEditorValidationMessage] = useState('')

  const [isValid, setIsValid] = useState(false)

  const [validEditables, setValidEditables] = useState([])
  const [invalidEditables, setInvalidEditables] = useState([])
  const [previewHtml, setPreviewHtml] = useState('')
  const [finalHtml, setFinalHtml] = useState('')

  const getHashTagsUsed = editorHtml => editorHtml.match(/#(.+?)#/gi)
  const stripHashTags = text => text.replace(/[#]/g, '')
  const getStyle = isEditable => {
    return !isEditable
      ? `background-color: #e74c3c; border-radius: 4px; color: #ffffff; padding: 4px 8px; word-wrap: break-word;`
      : `background-color: #1abc9c; border-radius: 4px; color: #ffffff; padding: 4px 8px; word-wrap: break-word;`
  }

  const processPreviewHtml = editorHtml => {
    var hashTagsInDocument = getHashTagsUsed(editorHtml)
    var validEditables = []
    var invalidEditables = []

    if (hashTagsInDocument) {
      hashTagsInDocument.forEach(ht => {
        var editable = editables.find(er => `#${er.name}#` === ht)
        if (editable) {
          validEditables.push(editable)
        } else {
          invalidEditables.push(ht)
        }
        var span = `<span style="${getStyle(editable)}">${stripHashTags(
          ht
        )}</span>`
        editorHtml = editorHtml.replace(ht, span)
      })
    }
    setPreviewHtml(editorHtml)
    setValidEditables(validEditables)
    setInvalidEditables(invalidEditables)
  }

  const processFinalHtml = editorHtml => {
    var hashTagsInDocument = getHashTagsUsed(editorHtml)
    if (hashTagsInDocument) {
      hashTagsInDocument.forEach(ht => {
        var editable = editables.find(er => `#${er.name}#` === ht)
        if (editable) editorHtml = editorHtml.replace(ht, editable.contentHtml)
      })
    }
    setFinalHtml(editorHtml)
  }

  useEffect(() => {
    var editorHtml = generateHtmlFromEditorState(editorState)
    processPreviewHtml(editorHtml)
    processFinalHtml(editorHtml)
  }, [editorState])

  // const generateEditorStateFromHtml = html => {
  //     const blockArray = htmlToDraft(html);
  //     const contentState = ContentState.createFromBlockArray(blockArray);
  //     const editorState = EditorState.createWithContent(contentState);
  //     return editorState;
  // };

  const generateHtmlFromEditorState = editorState => {
    const contentState = editorState.getCurrentContent()
    const raw = convertToRaw(contentState)
    const html = draftToHtml(raw)
    return html
  }

  useEffect(() => {
    setIsEditMode(initialFormData.id > 0)
    setName(initialFormData.name)
    setBrandingId(initialFormData.brandingId)
    if (initialFormData.rawEditorState) {
      try {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(initialFormData.rawEditorState))
          )
        )
      } catch (ex) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(
              '#An error occured loading the content#'
            )
          )
        )
        console.error(ex)
      }
    }
  }, [initialFormData])

  const [steps, setSteps] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [isActiveStepValid, setIsActiveStepValid] = useState(false)

  const validateActiveStep = () => {
    if (activeStep === 0 && nameIsValid) return true
    if (activeStep === 1 && brandingIsValid) return true
    if (activeStep === 2 && editorIsValid) return true
    return false
  }

  useEffect(() => {
    setSteps(
      isEditMode
        ? [
            'Update template',
            'Update the branding',
            'Update the content',
            'Preview and confirm',
          ]
        : [
            'Create a template',
            'Select the branding',
            'Add some content',
            'Preview and confirm',
          ]
    )
  }, [isEditMode])

  const templateNameMaxCharacters = 150
  const templateNameMinCharacters = 3

  useEffect(() => {
    if (name.length < templateNameMinCharacters) {
      setNameValidationMessage(
        `Enter at least ${templateNameMinCharacters} characters`
      )
      setNameIsValid(false)
    } else if (name.length > templateNameMaxCharacters) {
      setNameValidationMessage(
        `Enter ${templateNameMaxCharacters} or fewer characters`
      )
      setNameIsValid(false)
    } else if (
      currentTemplates.find(x => x.name.toLowerCase() === name.toLowerCase())
    ) {
      setNameValidationMessage('This name has already been taken')
      setNameIsValid(false)
    } else {
      setNameIsValid(true)
      setNameValidationMessage('Valid Input')
    }
    setBrandingIsValid(brandingId > 0)
    setEditorIsValid(generateHtmlFromEditorState(editorState).length > 10)
  }, [name, brandingId, editorState])

  useEffect(() => {
    setNameValidationMessage(nameValidationMessage)
    setBrandingValidationMessage(
      brandingIsValid ? 'Valid Input' : 'Select a branding'
    )
    setEditorValidationMessage(
      editorIsValid ? 'Valid Input' : 'Enter a template'
    )
    setIsValid(
      nameIsValid &&
        brandingIsValid &&
        editorIsValid &&
        invalidEditables.length === 0
    )
    setIsActiveStepValid(() => validateActiveStep())
  }, [
    nameIsValid,
    brandingIsValid,
    editorIsValid,
    activeStep,
    invalidEditables,
  ])

  const handleSubmit = e => {
    e.preventDefault()
    if (isValid) {
      var ids = []
      validEditables.forEach(e => ids.push(e.id))
      var returnedData = {
        id: initialFormData.id,
        name: name,
        serviceId: initialFormData.serviceId,
        isArchived: isArchived,
        contentHtml: finalHtml,
        rawEditorState: JSON.stringify(
          convertToRaw(editorState.getCurrentContent())
        ),
        brandingId: brandingId,
        isPublished: isPublished,
        editableRegionIds: ids,
      }
      onSubmit(returnedData)
    }
  }

  const [selectableEditables, setSelectableEditables] = useState([])
  useEffect(() => {
    var result = []
    editables.forEach(e => {
      result.push({
        id: e.id,
        name: e.name,
        value: `#${e.name}#`,
      })
    })
    setSelectableEditables(result)
  }, [editables])

  return (
    <>
      <AdminCard>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </AdminCard>
      <form onSubmit={handleSubmit}>
        {activeStep === 0 && (
          <AdminCard title={'Overview'}>
            <>
              <div>
                <FormControl
                  className={classes.formControl}
                  error={!nameIsValid}>
                  <TextField
                    disabled={isEditMode}
                    type='text'
                    label='Name'
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                  <FormHelperText>{nameValidationMessage}</FormHelperText>
                </FormControl>
              </div>
              {isEditMode && (
                <div>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isArchived}
                          onChange={e => setIsArchived(e.target.checked)}
                          color='primary'
                        />
                      }
                      label='Archived?'
                    />
                  </FormControl>
                </div>
              )}
            </>
          </AdminCard>
        )}
        {activeStep === 1 && (
          <AdminCard title={'Branding'}>
            <div>
              <FormControl
                className={classes.formControl}
                error={!brandingIsValid}>
                <InputLabel>Branding</InputLabel>
                <Select
                  value={brandingId}
                  onChange={e => setBrandingId(e.target.value)}>
                  <MenuItem value={0} disabled>
                    Please Select
                  </MenuItem>
                  {brandings.map(br => (
                    <MenuItem key={br.id} value={br.id}>
                      {br.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{brandingValidationMessage}</FormHelperText>
              </FormControl>
            </div>
          </AdminCard>
        )}
        {activeStep === 2 && (
          <AdminCard title={'Content'}>
            <Editor
              editorClassName={classes.textbox}
              editorState={editorState}
              onEditorStateChange={setEditorState}
              handlePastedText={() => false}
              toolbarCustomButtons={[
                <CustomEditorDropdown
                  customTitle={'Editables'}
                  customOptions={selectableEditables}
                />,
              ]}
            />
            <FormHelperText error={!editorIsValid}>
              {editorValidationMessage}
            </FormHelperText>
          </AdminCard>
        )}
        {activeStep === 3 && (
          <AdminDocumentPreview
            headerHtml={branding.headerHtml}
            footerHtml={branding.footerHtml}
            contentHtml={previewHtml}
          />
        )}
        <ActionBar
          leftContent={[
            {
              label: 'Back',
              show: activeStep !== 0,
              isPrimaryAction: false,
              onClick: () =>
                setActiveStep(prevActiveStep => prevActiveStep - 1),
            },
            {
              label: 'Next',
              show: activeStep !== steps.length - 1,
              isPrimaryAction: false,
              disabled: !isActiveStepValid,
              onClick: () =>
                setActiveStep(prevActiveStep => prevActiveStep + 1),
            },
          ]}
          rightContent={[
            {
              label: 'Save as Draft',
              show: activeStep === steps.length - 1,
              isPrimaryAction: false,
              disabled: !isValid,
              type: 'submit',
              onClick: () => setIsPublished(false),
            },
            {
              label: 'Publish',
              show: activeStep === steps.length - 1,
              isPrimaryAction: true,
              disabled: !isValid,
              type: 'submit',
              onClick: () => setIsPublished(true),
            },
          ]}
        />
      </form>
    </>
  )
}

export default DocumentTemplateForm
