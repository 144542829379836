import httpClient from '../utils/axios'
import {
  handleServiceResponse,
  handleServiceError,
} from '../utils/serviceResponseHelper'

function getServiceBranding() {
  return httpClient.get(`${window.config.SERVICE_API_URL}/v1/branding`).then(
    response => handleServiceResponse(response),
    error => handleServiceError(error)
  )
}

export default {
  getServiceBranding,
}
