// AUTH TYPES
export const STORE_USER = 'STORE_USER'
export const STORE_USER_ERROR = 'STORE_USER_ERROR'

export const SERVICE_ACCESS_REQUEST = 'SERVICE_ACCESS_REQUEST'
export const SERVICE_ACCESS_SUCCESS = 'SERVICE_ACCESS_SUCCESS'
export const SERVICE_ACCESS_FAILURE = 'SERVICE_ACCESS_FAILURE'

// NOTIFICATION TYPES
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

// BRANDING TYPES
export const GET_BRANDING_REQUEST = 'GET_BRANDING_REQUEST'
export const GET_BRANDING_SUCCESS = 'GET_BRANDING_SUCCESS'
export const GET_BRANDING_FAILURE = 'GET_BRANDING_FAILURE'
