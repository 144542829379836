import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import { makeStyles } from '@material-ui/core/styles'
import AdminCard from './adminCard'
import AdminDocumentPreview from './adminDocumentPreview'
import ActionBar from './actionBar'
import {
  generateEditorStateFromHtml,
  generateHtmlFromEditorState,
} from '../utils/editorHelpers'

const useStyles = makeStyles({
  formControl: { marginBottom: '20px', width: 200 },
  textbox: { minHeight: 260 },
})

const DocumentBrandingForm = ({
  initialFormData,
  currentBrandings,
  onSubmit,
}) => {
  const classes = useStyles()

  const [isEditMode, setIsEditMode] = useState(true)

  const [name, setName] = useState('')
  const [nameIsValid, setNameIsValid] = useState(false)
  const [nameValidationMessage, setNameValidationMessage] = useState('')

  const [isArchived, setIsArchived] = useState(false)

  const [headerEditorState, setHeaderEditorState] = useState(
    EditorState.createEmpty()
  )
  const [headerEditorIsValid, setHeaderEditorIsValid] = useState(false)
  const [
    headerEditorValidationMessage,
    setHeaderEditorValidationMessage,
  ] = useState('')

  const [footerEditorState, setFooterEditorState] = useState(
    EditorState.createEmpty()
  )
  const [footerEditorIsValid, setFooterEditorIsValid] = useState(false)
  const [
    footerEditorValidationMessage,
    setFooterEditorValidationMessage,
  ] = useState('')

  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setIsEditMode(initialFormData.id > 0)
    setName(initialFormData.name)
    setHeaderEditorState(
      generateEditorStateFromHtml(initialFormData.headerHtml)
    )
    setFooterEditorState(
      generateEditorStateFromHtml(initialFormData.footerHtml)
    )
  }, [initialFormData])

  const brandingNameMaxCharacters = 100
  const brandingNameMinCharacters = 3

  useEffect(() => {
    if (name.length < brandingNameMinCharacters) {
      setNameValidationMessage(
        `Enter at least ${brandingNameMinCharacters} characters`
      )
      setNameIsValid(false)
    } else if (name.length > brandingNameMaxCharacters) {
      setNameValidationMessage(
        `Enter ${brandingNameMaxCharacters} or fewer characters`
      )
      setNameIsValid(false)
    } else if (
      currentBrandings.find(x => x.name.toLowerCase() === name.toLowerCase())
    ) {
      setNameValidationMessage('This name has already been taken')
      setNameIsValid(false)
    } else {
      setNameIsValid(true)
      setNameValidationMessage('Valid Input')
    }
    setHeaderEditorIsValid(
      generateHtmlFromEditorState(headerEditorState).length > 10
    )
    setFooterEditorIsValid(
      generateHtmlFromEditorState(footerEditorState).length > 10
    )
  }, [name, headerEditorState, footerEditorState])

  const [steps, setSteps] = useState([])

  useEffect(() => {
    setSteps(
      isEditMode
        ? [
            'Update branding',
            'Update the header',
            'Update the footer',
            'Preview and confirm',
          ]
        : [
            'Create a branding',
            'Add a header',
            'Add a footer',
            'Preview and confirm',
          ]
    )
  }, [isEditMode])

  const [activeStep, setActiveStep] = useState(0)
  const [isActiveStepValid, setIsActiveStepValid] = useState(false)

  const validateActiveStep = () => {
    if (activeStep === 0 && nameIsValid) return true
    if (activeStep === 1 && headerEditorIsValid) return true
    if (activeStep === 2 && footerEditorIsValid) return true
    return false
  }

  useEffect(() => {
    setNameValidationMessage(nameValidationMessage)
    setHeaderEditorValidationMessage(
      headerEditorIsValid ? 'Valid Input' : 'Enter a header'
    )
    setFooterEditorValidationMessage(
      footerEditorIsValid ? 'Valid Input' : 'Enter a footer'
    )
    setIsValid(nameIsValid && headerEditorIsValid && footerEditorIsValid)
    setIsActiveStepValid(() => validateActiveStep())
  }, [nameIsValid, headerEditorIsValid, footerEditorIsValid, activeStep])

  const handleSubmit = e => {
    if (isValid) {
      e.preventDefault()
      var returnedData = {
        id: initialFormData.id,
        name: name,
        serviceId: initialFormData.serviceId,
        isArchived: isArchived,
        headerHtml: generateHtmlFromEditorState(headerEditorState),
        footerHtml: generateHtmlFromEditorState(footerEditorState),
      }
      onSubmit(returnedData)
    }
  }

  return (
    <>
      <AdminCard>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </AdminCard>
      <form onSubmit={handleSubmit}>
        {activeStep === 0 && (
          <AdminCard title={'Overview'}>
            <div>
              <FormControl className={classes.formControl} error={!nameIsValid}>
                <TextField
                  disabled={isEditMode}
                  type='text'
                  label='Name'
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <FormHelperText>{nameValidationMessage}</FormHelperText>
              </FormControl>
            </div>
            {isEditMode ? (
              <div>
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isArchived}
                        onChange={e => setIsArchived(e.target.checked)}
                        color='primary'
                      />
                    }
                    label='Archived?'
                  />
                </FormControl>
              </div>
            ) : null}
          </AdminCard>
        )}
        {activeStep === 1 && (
          <AdminCard title={'Header'}>
            <Editor
              editorClassName={classes.textbox}
              editorState={headerEditorState}
              onEditorStateChange={setHeaderEditorState}
            />
            <FormHelperText error={!headerEditorIsValid}>
              {headerEditorValidationMessage}
            </FormHelperText>
          </AdminCard>
        )}
        {activeStep === 2 && (
          <AdminCard title={'Footer'}>
            <Editor
              editorClassName={classes.textbox}
              editorState={footerEditorState}
              onEditorStateChange={setFooterEditorState}
            />
            <FormHelperText error={!footerEditorIsValid}>
              {footerEditorValidationMessage}
            </FormHelperText>
          </AdminCard>
        )}

        {activeStep === 3 && (
          <AdminDocumentPreview
            headerHtml={generateHtmlFromEditorState(headerEditorState)}
            footerHtml={generateHtmlFromEditorState(footerEditorState)}
            contentHtml={'Document Content'}
          />
        )}
        <ActionBar
          leftContent={[
            {
              label: 'Back',
              show: activeStep !== 0,
              isPrimaryAction: false,
              onClick: () =>
                setActiveStep(prevActiveStep => prevActiveStep - 1),
            },
            {
              label: 'Next',
              show: activeStep !== steps.length - 1,
              isPrimaryAction: false,
              disabled: !isActiveStepValid,
              onClick: () =>
                setActiveStep(prevActiveStep => prevActiveStep + 1),
            },
          ]}
          rightContent={[
            {
              label: isEditMode ? 'Update' : 'Create',
              show: activeStep === steps.length - 1,
              isPrimaryAction: true,
              disabled: !isValid,
              type: 'submit',
            },
          ]}
        />
      </form>
    </>
  )
}

export default DocumentBrandingForm
