import React, { useCallback, useRef, useEffect } from 'react'
import {
  createNewPage,
  appendPage,
  getOverflowContent,
} from '../utils/domHelpers'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  pages: {
    padding: '24px',
  },
})

const AdminDocumentPreview = ({ headerHtml, footerHtml, contentHtml }) => {
  const classes = useStyles()
  const pagesRef = useRef(null)

  const processPage = useCallback(({ page, header, footer }) => {
    const overflowContent = getOverflowContent(page)

    if (overflowContent.length <= 0) return

    const newPage = createNewPage({
      content: overflowContent,
      header: header,
      footer: footer,
    })
    appendPage(pagesRef.current, newPage)

    processPage({ page: newPage, header, footer })
  }, [])

  const setupPages = useCallback(
    ({ content, header, footer }) => {
      const startingPage = createNewPage({
        isFirstPage: true,
        content: content,
        header: header,
        footer: footer,
      })
      appendPage(pagesRef.current, startingPage)
      processPage({ page: startingPage, header, footer })
    },
    [processPage]
  )

  useEffect(() => {
    setupPages({
      content: contentHtml,
      header: headerHtml,
      footer: footerHtml,
    })
  }, [contentHtml, footerHtml, headerHtml, setupPages])

  return (
    <>
      <div className={classes.pages} ref={pagesRef} id='pages'></div>
    </>
  )
}

export default AdminDocumentPreview
