import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import AdminPageHeader from '../../../components/adminPageHeader'
import AdminBackdrop from '../../../components/adminBackdrop'
import DocumentTemplateForm from '../../../components/documentTemplateForm'

import templateService from '../../../services/documenttemplate.service'
import brandingService from '../../../services/documentbranding.service'
import { getEditableRegions } from '../../../services/editableregion.service'

const DocumentTemplateAdd = () => {
  let history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [brandings, setBrandings] = useState(null)
  const [currentTemplates, setCurrentTemplates] = useState(null)
  const [editables, setEditables] = useState(null)
  const [initialFormData] = useState({
    id: 0,
    name: '',
    serviceId: 1,
    isArchived: false,
    brandingId: 0,
    rawEditorState: null,
  })

  useEffect(() => {
    brandingService.getDocumentBrandings().then(res => setBrandings(res))

    templateService.getDocumentTemplates().then(res => setCurrentTemplates(res))

    getEditableRegions().then(regions => setEditables(regions))
  }, [])

  useEffect(() => {
    setIsLoading(!brandings || !editables || !currentTemplates)
  }, [brandings, editables])

  const handleSubmit = updateFormData => {
    setIsLoading(true)
    templateService.addDocumentTemplate(updateFormData).then(() => {
      setIsLoading(false)
      history.push('/admin/template')
    })
  }

  return (
    <>
      <AdminBackdrop show={isLoading} />
      {!isLoading ? (
        brandings && editables && currentTemplates ? (
          <>
            <AdminPageHeader
              title={'Add Template'}
              navActionLabel='Return to templates'
              navActionPath='/admin/template'
            />
            <DocumentTemplateForm
              initialFormData={initialFormData}
              currentTemplates={currentTemplates}
              brandings={brandings}
              editables={editables}
              onSubmit={handleSubmit}
            />
          </>
        ) : null
      ) : null}
    </>
  )
}

export default DocumentTemplateAdd
