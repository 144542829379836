import React from 'react'
import NavBar from '../components/navbar'
import PropTypes from 'prop-types'
import ThemeLoader from '../components/branding/themeLoader'

function MainLayout({ children, leftSubNav, rightSubNav, progressPercentage }) {
  return (
    <ThemeLoader>
      <NavBar
        leftSubNav={leftSubNav}
        rightSubNav={rightSubNav}
        progressPercentage={progressPercentage}
      />
      <main>{children}</main>
    </ThemeLoader>
  )
}

MainLayout.propTypes = {
  leftSubNav: PropTypes.func,
  rightSubNav: PropTypes.func,
  progressPercentage: PropTypes.number,
}

export default MainLayout
