import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Typography } from '@material-ui/core'
import EditableDialogWrapper from './editableDialogWrapper'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  editorBody: {
    border: '1px solid #F1F1F1',
    marginBottom: theme.spacing(1),
  },
}))

const HtmlDialog = ({
  title,
  description,
  open,
  currentEditorState,
  onConfirm,
  onReset,
  onClose,
}) => {
  const classes = useStyles()
  const [editorState, setEditorState] = useState(
    currentEditorState || EditorState.createEmpty()
  )
  return (
    <EditableDialogWrapper title={title} open={open} onClose={onClose}>
      <DialogContent dividers>
        {description && <Typography gutterBottom>{description}</Typography>}
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbar={{
            options: ['inline', 'list', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
          }}
          stripPastedStyles={true}
          editorClassName={classes.editorBody}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onReset} color='secondary'>
          Reset
        </Button>
        <Button
          variant='contained'
          onClick={() => onConfirm(editorState)}
          color='secondary'>
          Confirm
        </Button>
      </DialogActions>
    </EditableDialogWrapper>
  )
}

HtmlDialog.propTyes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  currentEditorState: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default HtmlDialog
