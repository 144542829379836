import React from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { Container } from '@material-ui/core'

import MainLayout from '../layouts/mainLayout'
import AdminPageHeader from '../components/adminPageHeader'

import DocumentTemplateList from './subpages/admin/documentTemplateList'
import DocumentTemplateAdd from './subpages/admin/documentTemplateAdd'
import DocumentTemplateEdit from './subpages/admin/documentTemplateEdit'

import EditableRegionList from './subpages/admin/editableRegionList'
import EditableRegionAdd from './subpages/admin/editableRegionAdd'
import EditableRegionEdit from './subpages/admin/editableRegionEdit'

import DocumentBrandingList from './subpages/admin/documentBrandingList'
import DocumentBrandingAdd from './subpages/admin/documentBrandingAdd'
import DocumentBrandingEdit from './subpages/admin/documentBrandingEdit'

import { SubNavButton } from '../components/navigation'

const AdminWelcome = () => {
  return (
    <>
      <AdminPageHeader title={'Admin'} />
      <p>
        Welcome to the document service admin tool, use the navbar above to
        select an area to edit.
      </p>
    </>
  )
}

const Admin = () => {
  const history = useHistory()

  const LeftSubNav = () => {
    return (
      <>
        <SubNavButton
          handleClick={() => history.push('/admin/template')}
          label='Templates'
        />
        <SubNavButton
          handleClick={() => history.push('/admin/editable')}
          label='Editables'
        />
        <SubNavButton
          handleClick={() => history.push('/admin/branding')}
          label='Branding'
        />
      </>
    )
  }

  return (
    <MainLayout leftSubNav={LeftSubNav}>
      <Container>
        {window.location.pathname === '/admin' ||
        window.location.pathname === '/admin/' ? (
          <AdminWelcome />
        ) : null}
        <Switch>
          <Route
            exact
            path='/admin/branding'
            component={DocumentBrandingList}
          />
          <Route
            exact
            path='/admin/branding/add'
            component={DocumentBrandingAdd}
          />
          <Route path='/admin/branding/:id' component={DocumentBrandingEdit} />

          <Route
            exact
            path='/admin/template'
            component={DocumentTemplateList}
          />
          <Route
            exact
            path='/admin/template/add'
            component={DocumentTemplateAdd}
          />
          <Route path='/admin/template/:id' component={DocumentTemplateEdit} />

          <Route exact path='/admin/editable' component={EditableRegionList} />
          <Route
            exact
            path='/admin/editable/add'
            component={EditableRegionAdd}
          />
          <Route path='/admin/editable/:id' component={EditableRegionEdit} />
        </Switch>
      </Container>
    </MainLayout>
  )
}

export default Admin
