import React, { useState } from 'react'
import { EditorState, ContentState } from 'draft-js'
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor'
import AdminCard from './adminCard'
import InputTypes from '../constants/editabletypes.constants'
import { Button } from '@material-ui/core'
import FreetypeDialog from '../components/editableDialogs/freetypeDialog'
import DatePickerDialog from '../components/editableDialogs/datePickerDialog'
import TimePickerDialog from '../components/editableDialogs/timePickerDialog'
import DropdownDialog from '../components/editableDialogs/dropdownDialog'
import HtmlDialog from '../components/editableDialogs/htmlDialog'
import CarouselDialog from './editableDialogs/carouselDialog'

const processedHTML = DraftPasteProcessor.processHTML(
  `
          <p>This is some sample text:</p>
          <ul>
              <li>Item 1</li>
              <li>Item 2</li>
          </ul>
      `
)

const contentState = ContentState.createFromBlockArray(processedHTML)

const generateCarouselOptions = editableRegionOptions => {
  var result = []
  var tempId = 1

  editableRegionOptions.forEach(ero => {
    if (!ero.isArchived) {
      result.push({
        id: tempId,
        value: ero.value,
      })
      tempId = tempId + 1
    }
  })
  return result
}

const EditableRegionFormPreview = ({ editableRegion, prePopulatedState }) => {
  const [editorState, setEditorState] = React.useState(
    prePopulatedState.getCurrentContent().hasText()
      ? prePopulatedState
      : EditorState.createWithContent(contentState)
  )
  const [showEditableDialog, setShowEditableDialog] = useState(false)

  const PreviewComponent = () => {
    switch (editableRegion.inputTypeId) {
      case InputTypes.FreeText:
        return (
          <FreetypeDialog
            title={editableRegion.name}
            description={editableRegion.description}
            open={showEditableDialog}
            onConfirm={() => setShowEditableDialog(false)}
            onReset={() => setShowEditableDialog(false)}
            onClose={() => setShowEditableDialog(false)}
            currentValue={`Example text for ${editableRegion.name}`}
          />
        )
      case InputTypes.DatePicker:
        return (
          <DatePickerDialog
            title={editableRegion.name}
            description={editableRegion.description}
            open={showEditableDialog}
            onConfirm={() => setShowEditableDialog(false)}
            onReset={() => setShowEditableDialog(false)}
            onClose={() => setShowEditableDialog(false)}
            currentDate='01/01/2020'
          />
        )
      case InputTypes.TimePicker:
        return (
          <TimePickerDialog
            title={editableRegion.name}
            description={editableRegion.description}
            open={showEditableDialog}
            onConfirm={() => setShowEditableDialog(false)}
            onReset={() => setShowEditableDialog(false)}
            onClose={() => setShowEditableDialog(false)}
            currentTime='9:00 AM'
          />
        )
      case InputTypes.Dropdown:
        return (
          <DropdownDialog
            title={editableRegion.name}
            description={editableRegion.description}
            open={showEditableDialog}
            onConfirm={() => setShowEditableDialog(false)}
            onReset={() => setShowEditableDialog(false)}
            onClose={() => setShowEditableDialog(false)}
            currentValue=''
            dropdownItems={editableRegion.editableRegionOptions}
          />
        )
      case InputTypes.HtmlEditor:
        return (
          <HtmlDialog
            title={editableRegion.name}
            description={editableRegion.description}
            open={showEditableDialog}
            onConfirm={state => setEditorState(state)}
            onReset={() => setShowEditableDialog(false)}
            onClose={() => setShowEditableDialog(false)}
            currentEditorState={editorState}
          />
        )
      case InputTypes.Carousel:
        return (
          <CarouselDialog
            title={editableRegion.name}
            description={editableRegion.description}
            open={showEditableDialog}
            onConfirm={state => setEditorState(state)}
            onReset={() => setShowEditableDialog(false)}
            onClose={() => setShowEditableDialog(false)}
            currentIndex={1}
            carouselOptions={generateCarouselOptions(
              editableRegion.editableRegionOptions
            )}
          />
        )
      default:
        throw new Error('Editable using unrecognised input type id')
    }
  }

  return (
    <AdminCard title={'Preview'}>
      <Button variant='outlined' onClick={() => setShowEditableDialog(true)}>
        Open Dialog
      </Button>
      <PreviewComponent />
    </AdminCard>
  )
}

export default EditableRegionFormPreview
