import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import {
  getEditableRegionInputTypeDescription,
  getEditableRegions,
} from '../../../services/editableregion.service'

import AdminPageHeader from '../../../components/adminPageHeader'
import AdminCard from '../../../components/adminCard'
import AdminCardHeader from '../../../components/adminCardHeader'
import AdminBackdrop from '../../../components/adminBackdrop'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: '20px',
    width: 200,
  },
  submitControl: {
    marginTop: '20px',
  },
  arrowIcon: {
    color: theme.palette.primary.main,
  },
}))

const EditableRegions = () => {
  const classes = useStyles()

  const [isLoadingList, setIsLoadingList] = useState(false)
  const [editableRegions, setEditableRegions] = useState([])

  const [filterInputTypeId, setfilterInputTypeId] = useState(0)
  const [filterText, setFilterText] = useState('')
  const [filteredAndSorted, setFilteredAndSorted] = useState([])
  const [filteredAndSortedArchived, setFilteredAndSortedArchived] = useState([])
  const [includeArchived, setIncludeArchived] = useState(false)

  useEffect(() => {
    setIsLoadingList(true)
    getEditableRegions().then(result => {
      setIsLoadingList(false)
      setEditableRegions(result)
    })
  }, [])

  useEffect(() => {
    var filteredAndSorted = editableRegions
      .filter(
        er =>
          (er.inputTypeId === filterInputTypeId || filterInputTypeId === 0) &&
          (er.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
            filterText === '')
      )
      .sort((a, b) => a.name > b.name)
    setFilteredAndSorted(filteredAndSorted.filter(er => !er.isArchived))
    setFilteredAndSortedArchived(filteredAndSorted.filter(er => er.isArchived))
  }, [editableRegions, filterInputTypeId, filterText])

  return (
    <>
      <AdminBackdrop show={isLoadingList} />

      {!isLoadingList ? (
        <>
          <AdminPageHeader
            title={'Editables'}
            navActionLabel='Create new'
            navActionPath='/admin/editable/add'
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AdminCard>
                <AdminCardHeader title={'Filter'} />
                <div style={{ marginBottom: 20 }}>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginRight: 10 }}>
                    <InputLabel>Input Type</InputLabel>
                    <Select
                      value={filterInputTypeId}
                      onChange={e => setfilterInputTypeId(e.target.value)}>
                      <MenuItem value={0}>None</MenuItem>
                      <MenuItem value={1}>Free Text</MenuItem>
                      <MenuItem value={2}>Date Picker</MenuItem>
                      <MenuItem value={3}>Time Picker</MenuItem>
                      <MenuItem value={4}>Drop Down</MenuItem>
                      <MenuItem value={5}>Html Editor</MenuItem>
                      <MenuItem value={6}>Carousel</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginRight: 10 }}>
                    <TextField
                      type='text'
                      label='Name'
                      value={filterText}
                      onChange={e => setFilterText(e.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginTop: 10 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includeArchived}
                          onChange={e => setIncludeArchived(e.target.checked)}
                          color='primary'
                        />
                      }
                      label='Include Archived?'
                    />
                  </FormControl>
                </div>
                <AdminCardHeader title={'Existing Editables'} />
                {!isLoadingList && includeArchived ? <h3>Current:</h3> : null}
                {!isLoadingList ? (
                  filteredAndSorted.length > 0 ? (
                    <List dense>
                      {filteredAndSorted.map(er => (
                        <ListItem
                          key={er.id}
                          button
                          component={Link}
                          to={`/admin/editable/${er.id}`}>
                          <ListItemText
                            primary={er.name}
                            secondary={getEditableRegionInputTypeDescription(
                              er.inputTypeId
                            )}
                          />
                          <ArrowForwardIcon className={classes.arrowIcon} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <p>No editable regions</p>
                  )
                ) : null}
                {!isLoadingList && includeArchived ? <h3>Archived:</h3> : null}
                {!isLoadingList && includeArchived ? (
                  filteredAndSortedArchived.length > 0 ? (
                    <List dense>
                      {filteredAndSortedArchived.map(er => (
                        <ListItem
                          key={er.id}
                          button
                          component={Link}
                          to={`/admin/editable/${er.id}`}>
                          <ListItemText
                            primary={er.name}
                            secondary={getEditableRegionInputTypeDescription(
                              er.inputTypeId
                            )}
                          />
                          <ArrowForwardIcon className={classes.arrowIcon} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <p>No archived editable regions</p>
                  )
                ) : null}
              </AdminCard>
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  )
}

export default EditableRegions
