import React from 'react'
import AdminCard from './adminCard'
import { Editor } from 'react-draft-wysiwyg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  editorBody: {
    border: '1px solid #F1F1F1',
  },
})

const editableRegionPrePopulate = ({
  prePopulatedState,
  onPrePopulatedStateChanged,
}) => {
  // const classes = useStyles()

  return (
    <AdminCard title='Pre-Populate Text'>
      <Editor
        editorState={prePopulatedState}
        onEditorStateChange={onPrePopulatedStateChanged}
        toolbar={{
          options: ['inline', 'list', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
        }}
        // editorClassName={classes.editorBody}
      />
    </AdminCard>
  )
}

export default editableRegionPrePopulate
