import { combineReducers } from 'redux'
import authReducer from './auth.reducer'
import notificationReducer from './notification.reducer'
import brandingReducer from './branding.reducer'

export default combineReducers({
  auth: authReducer,
  branding: brandingReducer,
  notifications: notificationReducer,
})
