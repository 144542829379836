import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'

import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'

import AdminPageHeader from '../../../components/adminPageHeader'
import AdminBackdrop from '../../../components/adminBackdrop'
import DocumentTemplateForm from '../../../components/documentTemplateForm'

import templateService from '../../../services/documenttemplate.service'
import brandingService from '../../../services/documentbranding.service'
import { getEditableRegions } from '../../../services/editableregion.service'

const DocumentTemplateEdit = () => {
  let history = useHistory()
  let { id } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const [brandings, setBrandings] = useState(null)
  const [editables, setEditables] = useState(null)

  const [initialFormData, setInitialFormData] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    templateService.getDocumentTemplate(parseInt(id)).then(template => {
      setInitialFormData({
        id: template.id,
        name: template.name,
        serviceId: template.serviceId,
        isArchived: template.isArchived,
        brandingId:
          template.latestDocumentTemplateVersion.documentTemplateBrandingId,
        rawEditorState: template.latestDocumentTemplateVersion.rawEditorState,
      })
    })

    brandingService
      .getDocumentBrandings()
      .then(brandings => setBrandings(brandings))

    getEditableRegions().then(regions => setEditables(regions))
  }, [id])

  useEffect(() => {
    setIsLoading(!brandings || !editables || !initialFormData)
  }, [brandings, editables, initialFormData])

  const handleSubmit = updateFormData => {
    setIsLoading(true)
    templateService.updateDocumentTemplate(updateFormData).then(() => {
      setIsLoading(false)
      history.push('/admin/template')
    })
  }

  return (
    <>
      <AdminBackdrop show={isLoading} />
      {!isLoading ? (
        initialFormData && brandings && editables ? (
          <>
            <AdminPageHeader
              title={'Update Template'}
              navActionLabel='Return to templates'
              navActionPath='/admin/template'
            />
            <DocumentTemplateForm
              initialFormData={initialFormData}
              currentTemplates={[]}
              brandings={brandings}
              editables={editables}
              onSubmit={handleSubmit}
            />
          </>
        ) : null
      ) : null}
    </>
  )
}

export default DocumentTemplateEdit
