import httpClient from '../utils/axios'
import {
  handleServiceResponse,
  handleServiceError,
} from '../utils/serviceResponseHelper'

const completeDocument = async ({ documentGuid, contentHtml }) => {
  return httpClient
    .put(`${window.config.DOCUMENT_API_URL}/v1/document/complete`, {
      documentGuid: documentGuid,
      contentHtml,
    })
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const saveDocument = async ({ documentGuid, contentHtml }) => {
  return httpClient
    .put(`${window.config.DOCUMENT_API_URL}/v1/document/update`, {
      documentGuid: documentGuid,
      contentHtml,
    })
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const createDocument = async ({ documentTemplateId, documentGuid, caseId }) => {
  const parsedDocumentTemplateId = parseInt(documentTemplateId)
  const parsedCaseId = parseInt(caseId)

  return httpClient
    .post(`${window.config.DOCUMENT_API_URL}/v1/document/create`, {
      documentTemplateId: isNaN(parsedDocumentTemplateId)
        ? 0
        : parsedDocumentTemplateId,
      caseId: isNaN(parsedCaseId) ? 0 : parsedCaseId,
      documentGuid: documentGuid,
    })
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const continueDocument = async ({ existingDocumentGuid }) => {
  return httpClient
    .get(
      `${window.config.DOCUMENT_API_URL}/v1/document/continue?existingDocumentGuid=${existingDocumentGuid}`
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

export default {
  completeDocument,
  saveDocument,
  createDocument,
  continueDocument,
}
