import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  subNavButton: {
    color: props =>
      props.disabled
        ? '#a1a1a1'
        : theme.palette.getContrastText(theme.palette.navBackground.main),
    textTransform: 'uppercase',
    fontSize: '12px',
    border: 0,
    background: 'none',
    padding: 0,
    cursor: props => (props.disabled ? 'auto' : 'pointer'),
  },
}))

export const SubNavButton = ({ label, handleClick, ...props }) => {
  const classes = useStyles({ ...props })
  return (
    <button className={classes.subNavButton} onClick={handleClick} {...props}>
      {label}
    </button>
  )
}

SubNavButton.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}
