import React, { useState, useEffect, useRef, useCallback } from 'react'
import MainLayout from '../layouts/mainLayout'
import { Container } from '@material-ui/core'
import InputTypes from '../constants/editabletypes.constants'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import {
  makeEditableInvalid,
  makeEditableValid,
  createNewPage,
  getOverflowContent,
  getDocumentHTML,
  addEditableClickHandlers,
  appendPage,
  getHtmlForEditableId,
} from '../utils/domHelpers'
import { SubNavButton } from '../components/navigation'
import wizardService from '../services/wizard.service'
import { useHistory, useLocation } from 'react-router-dom'
import ScrollToTop from '../components/navigation/ScrollToTop'
import { useNavigation } from '../contexts/navigationContext'
import mergeTypes from '../constants/mergetypes.constants'
import { useDispatch } from 'react-redux'
import { enqueueSnackbar } from '../actions/notification.actions'
import queryString from 'query-string'
import {
  generateEditorStateFromHtml,
  generateHtmlFromEditorState,
} from '../utils/editorHelpers'
import HtmlDialog from '../components/editableDialogs/htmlDialog'
import DatePickerDialog from '../components/editableDialogs/datePickerDialog'
import TimePickerDialog from '../components/editableDialogs/timePickerDialog'
import DropdownDialog from '../components/editableDialogs/dropdownDialog'
import FreetypeDialog from '../components/editableDialogs/freetypeDialog'
import CarouselDialog from '../components/editableDialogs/carouselDialog'

const useStyles = makeStyles({
  pages: {
    padding: '24px',
  },
  previewDisclaimer: {
    color: '#a1a1a1',
    textAlign: 'center',
    margin: 0,
  },
  contentContainer: {
    paddingTop: '16px',
  },
})

function Wizard() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { returnUrl, returnToUrl } = useNavigation()
  const [progress, setProgress] = useState(0)
  const pagesRef = useRef(null)
  const [editableLookups, setEditableLookups] = useState({})
  const [htmlEditorState, setHtmlEditorState] = useState({})
  const [carouselState, setCarouselState] = useState({})
  const [documentData, setDocumentData] = useState({})
  const [caseData, setCaseData] = useState({})
  const [employeeData, setEmployeeData] = useState({})
  const [showEditableDialog, setShowEditableDialog] = useState(false)
  const [selectedEditable, setSelectedEditable] = useState(null)

  const getAllEditables = () => {
    return pagesRef.current.querySelectorAll('[data-tag-id]')
  }

  const processPage = useCallback(({ page, header, footer }) => {
    const overflowContent = getOverflowContent(page)

    if (overflowContent.length <= 0) return

    const newPage = createNewPage({
      content: overflowContent,
      header: header,
      footer: footer,
    })
    appendPage(pagesRef.current, newPage)

    processPage({ page: newPage, header, footer })
  }, [])

  const selectEditable = e => {
    const { target } = e
    const closest = target.closest('[data-tag-id]')
    if (closest) {
      const id = closest.getAttribute('data-tag-id')
      const bounds = closest.getBoundingClientRect()

      setSelectedEditable({
        id,
        label: editableLookups[id].name,
        description: editableLookups[id].description,
        inputTypeId: editableLookups[id].inputTypeId,
        options: editableLookups[id].options,
        coords: { x: bounds.left, y: bounds.top },
        DOMElement: closest,
      })
      setShowEditableDialog(true)
    }
  }

  const deselectEditable = () => {
    setSelectedEditable(null)
    setShowEditableDialog(false)
    refreshPages()
  }

  const setupPages = useCallback(
    ({ content, header, footer }) => {
      const startingPage = createNewPage({
        isFirstPage: true,
        content: content,
        header: header,
        footer: footer,
      })
      appendPage(pagesRef.current, startingPage)
      processPage({ page: startingPage, header, footer })
    },
    [processPage]
  )

  // initialise document
  useEffect(() => {
    const getInitialData = async () => {
      const parsedQuery = queryString.parse(location.search)
      const {
        existingDocumentGuid,
        documentGuid,
        templateId,
        caseId,
      } = parsedQuery

      const creatingNewDocument = location.pathname.includes('new')

      // redirect out if required params are missing
      if (
        (!creatingNewDocument && !existingDocumentGuid) ||
        (creatingNewDocument && (!documentGuid || !templateId))
      ) {
        history.push('/oops')
        dispatch(
          enqueueSnackbar({
            message: 'Document request missing required parameters',
            options: {
              variant: 'error',
            },
          })
        )
        return
      }

      let response
      try {
        if (creatingNewDocument) {
          response = await wizardService.createDocument({
            documentTemplateId: templateId,
            documentGuid,
            caseId,
          })
        } else {
          response = await wizardService.continueDocument({
            existingDocumentGuid,
          })
        }
      } catch (error) {
        console.error(error)
        history.push('/oops')
        dispatch(
          enqueueSnackbar({
            message: 'Could not load document. Please try again.',
            options: {
              variant: 'error',
            },
          })
        )
        return
      }

      if (!response) {
        history.push('/oops')
        dispatch(
          enqueueSnackbar({
            message: 'Could not load document. Please try again.',
            options: {
              variant: 'error',
            },
          })
        )
        return
      }

      const {
        documentData = null,
        caseData = null,
        employeeData = null,
      } = response

      setDocumentData(documentData)
      setCaseData(caseData)
      setEmployeeData(employeeData)
    }

    getInitialData()
  }, [dispatch, history])

  // set up editables and pages
  useEffect(() => {
    const editableDetails = {}
    const editableHtmlEditorState = {}
    const {
      editableRegions = [],
      contentHtml = '',
      branding = {},
    } = documentData
    const { headerHtml = '', footerHtml = '' } = branding
    const creatingNewDocument = location.pathname.includes('new')

    // dont do anything if we havent got document data yet
    if (!Object.keys(documentData).length) return

    setupPages({
      content: contentHtml,
      header: headerHtml,
      footer: footerHtml,
    })

    const allEditables = getAllEditables()

    editableRegions.forEach(editableRegion => {
      const { editableRegionId, ...rest } = editableRegion
      editableDetails[editableRegionId] = {
        ...rest,
      }

      // set up html editor state
      if (editableRegion.inputTypeId === InputTypes.HtmlEditor) {
        const editorStateFromHtml =
          creatingNewDocument && editableRegion.prePopulatedState
            ? generateEditorStateFromHtml(editableRegion.prePopulatedState)
            : generateEditorStateFromHtml(
                getHtmlForEditableId(allEditables, editableRegionId)
              )
        editableHtmlEditorState[editableRegionId] = editorStateFromHtml
      }
    })

    setEditableLookups(editableDetails)
    setHtmlEditorState(editableHtmlEditorState)
  }, [documentData, setupPages])

  useEffect(() => {
    const allEditables = getAllEditables()
    addEditableClickHandlers(allEditables, selectEditable)
  }, [editableLookups])

  // pre-populate editable values
  useEffect(() => {
    const editableList = getAllEditables()
    editableList.forEach(editable => {
      const id = editable.getAttribute('data-tag-id')
      const editableMergeData =
        editableLookups[id] && editableLookups[id].editableMergeField

      // merge data depending on merge type
      if (caseData && editableMergeData) {
        let mergedValue
        switch (editableMergeData.type) {
          case mergeTypes.caseData:
            mergedValue = (caseData && caseData[editableMergeData.value]) || ''
            break
          case mergeTypes.employeeData:
            mergedValue =
              (employeeData && employeeData[editableMergeData.value]) || ''
            break
          default:
            mergedValue = editableLookups[id].name
        }

        // if merged value doesnt exist, set to editable name
        if (!mergedValue) {
          editable.innerText = editableLookups[id].name
          makeEditableInvalid(editable)
          return
        }

        editable.innerText = mergedValue
        makeEditableValid(editable)
      }
    })
  }, [caseData, employeeData, editableLookups])

  const updateDropdownValue = value => {
    if (!value) {
      resetDropdownValue()
      return
    }

    selectedEditable.DOMElement.innerText = value
    makeEditableValid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const resetDropdownValue = () => {
    selectedEditable.DOMElement.innerText = selectedEditable.label
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const updateFreetypeValue = value => {
    if (value === selectedEditable.label) {
      resetFreetypeValue()
      return
    }

    selectedEditable.DOMElement.innerText = value
    makeEditableValid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const resetFreetypeValue = () => {
    selectedEditable.DOMElement.innerText = selectedEditable.label
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const updateDateValue = date => {
    const formatted = new moment(date).format('DD/MM/YYYY')
    selectedEditable.DOMElement.innerText = formatted
    makeEditableValid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const resetDateValue = () => {
    selectedEditable.DOMElement.innerText = selectedEditable.label
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const updateTimeValue = time => {
    const formatted = new moment(time).format('h:mm A')
    selectedEditable.DOMElement.innerText = formatted
    makeEditableValid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const resetTimeValue = () => {
    selectedEditable.DOMElement.innerText = selectedEditable.label
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const updateHtmlEditorState = editorState => {
    const editorContent = editorState.getCurrentContent()
    const prePopulatedState =
      editableLookups[selectedEditable.id].prePopulatedState
    const editorHtml = generateHtmlFromEditorState(editorState)
    const currentLabel = selectedEditable.label
    const editorText = editorContent.getPlainText()

    if (
      !editorContent.hasText() ||
      (prePopulatedState && editorHtml === prePopulatedState) ||
      (!prePopulatedState && editorText === currentLabel)
    ) {
      resetHtmlEditorState()
      return
    }

    setHtmlEditorState({
      ...htmlEditorState,
      [selectedEditable.id]: editorState,
    })

    selectedEditable.DOMElement.innerHTML = editorHtml
    makeEditableValid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const resetHtmlEditorState = () => {
    const prePopulatedState =
      editableLookups[selectedEditable.id].prePopulatedState
    const defaultValue = prePopulatedState || `<p>${selectedEditable.label}</p>`

    setHtmlEditorState({
      ...htmlEditorState,
      [selectedEditable.id]: generateEditorStateFromHtml(defaultValue),
    })
    selectedEditable.DOMElement.innerHTML = defaultValue
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const updateCarouselValue = (selectedId, selectedHtml) => {
    setCarouselState({ ...carouselState, [selectedEditable.id]: selectedId })
    selectedEditable.DOMElement.innerHTML = selectedHtml
    makeEditableValid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const resetCarouselValue = () => {
    selectedEditable.DOMElement.innerHTML = `<p>${selectedEditable.label}</p>`
    setCarouselState({ ...carouselState, [selectedEditable.id]: 0 })
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  // calculate progress
  useEffect(() => {
    if (!showEditableDialog) {
      const editableList = getAllEditables()
      const editablesArray = Array.from(editableList)

      if (editablesArray.length && Object.keys(editableLookups).length) {
        const completed = editablesArray.filter(x => {
          const tagId = x.getAttribute('data-tag-id')
          const inputTypeId = editableLookups[tagId].inputTypeId
          const name = editableLookups[tagId].name
          const innerText = x.innerText
          const prePopulatedState = editableLookups[tagId].prePopulatedState

          let completed = false
          if (inputTypeId === InputTypes.HtmlEditor && prePopulatedState) {
            const editorHtml = generateHtmlFromEditorState(
              htmlEditorState[tagId]
            )
            completed = editorHtml !== prePopulatedState
          } else {
            completed = innerText !== name
          }

          return !!innerText && completed
        }).length
        const progress = Math.round((100 / editablesArray.length) * completed)
        setProgress(isNaN(progress) ? 0 : progress)
      }
    }
  }, [editableLookups, showEditableDialog])

  const refreshPages = () => {
    const { branding = { headerHtml: '', footerHtml: '' } } = documentData
    const pagesContainer = document.getElementById('pages')
    const originalHtml = getDocumentHTML(pagesContainer)
    pagesContainer.innerHTML = ''
    setupPages({
      content: originalHtml,
      header: branding.headerHtml,
      footer: branding.footerHtml,
    })
    addEditableClickHandlers(getAllEditables(), selectEditable)
  }

  const PopoutComponent = () => {
    switch (selectedEditable.inputTypeId) {
      case InputTypes.FreeText:
        return (
          <FreetypeDialog
            title={selectedEditable.label}
            description={selectedEditable.description}
            open={showEditableDialog}
            onConfirm={updateFreetypeValue}
            onReset={resetFreetypeValue}
            onClose={deselectEditable}
            currentValue={selectedEditable.DOMElement.innerText}
          />
        )
      case InputTypes.DatePicker:
        return (
          <DatePickerDialog
            title={selectedEditable.label}
            description={selectedEditable.description}
            open={showEditableDialog}
            onConfirm={updateDateValue}
            onReset={resetDateValue}
            onClose={deselectEditable}
            currentDate={selectedEditable.DOMElement.innerText}
          />
        )
      case InputTypes.TimePicker:
        return (
          <TimePickerDialog
            title={selectedEditable.label}
            description={selectedEditable.description}
            open={showEditableDialog}
            onConfirm={updateTimeValue}
            onReset={resetTimeValue}
            onClose={deselectEditable}
            currentTime={selectedEditable.DOMElement.innerText}
          />
        )
      case InputTypes.Dropdown:
        return (
          <DropdownDialog
            title={selectedEditable.label}
            description={selectedEditable.description}
            open={showEditableDialog}
            onConfirm={updateDropdownValue}
            onReset={resetDropdownValue}
            onClose={deselectEditable}
            currentValue={selectedEditable.DOMElement.innerText}
            dropdownItems={selectedEditable.options}
          />
        )
      case InputTypes.HtmlEditor:
        return (
          <HtmlDialog
            title={selectedEditable.label}
            description={selectedEditable.description}
            open={showEditableDialog}
            currentEditorState={htmlEditorState[selectedEditable.id]}
            onConfirm={updateHtmlEditorState}
            onReset={resetHtmlEditorState}
            onClose={deselectEditable}
          />
        )
      case InputTypes.Carousel:
        return (
          <CarouselDialog
            title={selectedEditable.label}
            description={selectedEditable.description}
            open={showEditableDialog}
            onConfirm={updateCarouselValue}
            onReset={resetCarouselValue}
            onClose={deselectEditable}
            currentIndex={carouselState[selectedEditable.id]}
            carouselOptions={selectedEditable.options}
          />
        )
      default:
        throw new Error('Editable using un-recognised input type id')
    }
  }

  const completeDocument = async () => {
    const pagesContainer = document.getElementById('pages')
    try {
      await wizardService.completeDocument({
        documentGuid: documentData.documentGuid,
        contentHtml: getDocumentHTML(pagesContainer),
      })
      dispatch(
        enqueueSnackbar({
          message: 'Completed document',
          options: {
            variant: 'success',
          },
        })
      )
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'Could not complete document. Please try again.',
          options: {
            variant: 'error',
          },
        })
      )
    }
  }

  const saveDocument = async () => {
    const pagesContainer = document.getElementById('pages')

    try {
      await wizardService.saveDocument({
        documentGuid: documentData.documentGuid,
        contentHtml: getDocumentHTML(pagesContainer),
      })
      dispatch(
        enqueueSnackbar({
          message: 'Saved document',
          options: {
            variant: 'success',
          },
        })
      )
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'Could not save document. Please try again.',
          options: {
            variant: 'error',
          },
        })
      )
    }
  }

  const leftSubNav = () => {
    return (
      <>
        {returnUrl && <SubNavButton handleClick={returnToUrl} label='Back' />}
      </>
    )
  }

  const rightSubNav = () => {
    return (
      <>
        <SubNavButton handleClick={saveDocument} label='Save' />
        <SubNavButton
          handleClick={completeDocument}
          label='Complete'
          disabled={progress !== 100}
        />
      </>
    )
  }

  return (
    <MainLayout
      leftSubNav={leftSubNav}
      rightSubNav={rightSubNav}
      progressPercentage={progress}>
      <Container className={classes.contentContainer}>
        {selectedEditable && <PopoutComponent />}
        <p className={classes.previewDisclaimer}>
          <small>
            <em>
              <strong>Note:</strong> Document preview is an approximation of the
              final result
            </em>
          </small>
        </p>
        <h1>{(documentData && documentData.documentName) || ''}</h1>
        <div className={classes.pages} ref={pagesRef} id='pages'></div>
      </Container>
      <ScrollToTop />
    </MainLayout>
  )
}

export default Wizard
