import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'

import AdminBackdrop from '../../../components/adminBackdrop'
import AdminPageHeader from '../../../components/adminPageHeader'
import DocumentBrandingForm from '../../../components/documentBrandingForm'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'

import documentBrandingService from '../../../services/documentbranding.service'

const DocumentBrandingEdit = () => {
  let history = useHistory()
  let { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [documentBranding, setDocumentBranding] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    documentBrandingService.getDocumentBranding(parseInt(id)).then(result => {
      setIsLoading(false)
      setDocumentBranding(result)
    })
  }, [id])

  const onSubmit = updatedBranding => {
    setIsUpdating(true)
    documentBrandingService.updateDocumentBranding(updatedBranding).then(() => {
      setIsUpdating(false)
      history.push('/admin/branding')
    })
  }

  return (
    <>
      <AdminBackdrop show={isLoading || isUpdating} />
      {!isLoading && !isUpdating && documentBranding ? (
        <>
          <AdminPageHeader
            title={'Update Branding'}
            navActionLabel='Return to branding'
            navActionPath='/admin/branding'
          />
          <DocumentBrandingForm
            initialFormData={documentBranding}
            currentBrandings={[]}
            onSubmit={onSubmit}
          />
        </>
      ) : null}
    </>
  )
}

export default DocumentBrandingEdit
