import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import AdminBackdrop from '../../../components/adminBackdrop'
import AdminPageHeader from '../../../components/adminPageHeader'
import DocumentBrandingForm from '../../../components/documentBrandingForm'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'

import documentBrandingService from '../../../services/documentbranding.service'

const DocumentBrandingAdd = () => {
  let history = useHistory()

  const [initialFormData] = useState({
    id: 0,
    serviceId: 1,
    name: '',
    headerHtml: '',
    footerHtml: '',
    isArchived: false,
  })

  const [documentBrandings, setDocumentBrandings] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    documentBrandingService.getDocumentBrandings().then(result => {
      setIsLoading(false)
      setDocumentBrandings(result)
    })
  }, [])

  const onSubmit = newBranding => {
    setIsCreating(true)
    documentBrandingService.addDocumentBranding(newBranding).then(() => {
      setIsCreating(false)
      history.push('/admin/branding')
    })
  }

  return (
    <>
      <AdminBackdrop show={isCreating || isLoading} />
      {!isLoading && (
        <>
          <AdminPageHeader
            title={'Create Branding'}
            navActionLabel='Return to branding'
            navActionPath='/admin/branding'
          />
          <DocumentBrandingForm
            initialFormData={initialFormData}
            currentBrandings={documentBrandings}
            onSubmit={onSubmit}
          />
        </>
      )}
    </>
  )
}

export default DocumentBrandingAdd
