import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

export const generateEditorStateFromHtml = html => {
  const blockArray = htmlToDraft(html)
  const contentState = ContentState.createFromBlockArray(blockArray)
  const editorState = EditorState.createWithContent(contentState)
  return editorState
}

export const generateHtmlFromEditorState = editorState => {
  const contentState = editorState.getCurrentContent()
  const raw = convertToRaw(contentState)
  const html = draftToHtml(raw)
  return html
}
