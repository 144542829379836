export const makeEditableValid = editable => {
  editable.classList.remove('invalid')
  editable.classList.add('valid')
}

export const makeEditableInvalid = editable => {
  editable.classList.remove('valid')
  editable.classList.add('invalid')
}

export const createNewPage = ({ isFirstPage, content, header, footer }) => {
  // create new page
  const newPage = document.createElement('div')
  newPage.className = 'page'

  // set header html
  const headerDiv = document.createElement('div')
  headerDiv.className = 'page-header'
  headerDiv.innerHTML = isFirstPage ? header || '' : ''

  // set body content
  const bodyDiv = document.createElement('div')
  bodyDiv.className = 'page-body'
  const bodyContent = document.createElement('div')
  bodyContent.className = 'page-body-content'

  if (typeof content == 'string') {
    bodyContent.innerHTML = content
  } else if (Array.isArray(content)) {
    content.forEach(r => bodyContent.appendChild(r))
  }

  //set footer html
  const footerDiv = document.createElement('div')
  footerDiv.className = 'page-footer'
  footerDiv.innerHTML = footer || ''

  // append page sections
  newPage.appendChild(headerDiv)
  bodyDiv.appendChild(bodyContent)
  newPage.appendChild(bodyDiv)
  newPage.appendChild(footerDiv)
  return newPage
}

export const getOverflowContent = page => {
  let removed = []

  const pageBody = page.children[1]
  const pageBodyContent = pageBody.children[0]
  while (pageBodyContent.offsetHeight > pageBody.offsetHeight) {
    removed.unshift(
      pageBodyContent.children[pageBodyContent.children.length - 1]
    )
    pageBodyContent.removeChild(
      pageBodyContent.children[pageBodyContent.children.length - 1]
    )
  }

  return removed
}

export const getDocumentHTML = pagesContainer => {
  let html = ''
  const pages = Array.from(pagesContainer.children)
  pages.forEach(page => {
    const pageBody = page.children[1]
    const pageBodyContent = pageBody.children[0]
    html = html.concat(pageBodyContent.innerHTML)
  })
  return html
}

export const addEditableClickHandlers = (editablesNodeList, handleClick) => {
  const editablesArray = Array.from(editablesNodeList)
  return editablesArray.map(e => {
    e.addEventListener('click', handleClick)
    return e
  })
}

export const getHtmlForEditableId = (editablesNodeList, editableId) => {
  const editablesArray = Array.from(editablesNodeList)

  const editable = editablesArray.find(
    e => parseInt(e.getAttribute('data-tag-id')) === editableId
  )

  if (!editable) {
    console.error(
      `Exptected to find editable with id:${editableId} but does not exist in document`
    )
    return null
  }

  return editable.innerHTML
}

export const appendPage = (pageContainer, newPage) => {
  pageContainer.appendChild(newPage)
}
