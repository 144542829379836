import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import AdminPageHeader from '../../../components/adminPageHeader'
import AdminCard from '../../../components/adminCard'
import AdminCardHeader from '../../../components/adminCardHeader'
import AdminBackdrop from '../../../components/adminBackdrop'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { makeStyles } from '@material-ui/core/styles'

import templateService from '../../../services/documenttemplate.service'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: '20px',
    width: 200,
  },
  submitControl: {
    marginTop: '20px',
  },
  arrowIcon: {
    color: theme.palette.primary.main,
  },
}))

const TemplateListItemText = ({ template }) => {
  const [primary, setPrimary] = useState('')
  const [secondary, setSecondary] = useState('')

  useEffect(() => {
    if (template.name) {
      setPrimary(template.name)
    }

    if (
      template.publishedDocumentTemplateVersion &&
      template.latestDocumentTemplateVersion
    ) {
      if (
        template.latestDocumentTemplateVersion.id !==
        template.publishedDocumentTemplateVersion.id
      ) {
        setSecondary('Latest version is not published')
      }
    } else {
      setSecondary('Latest version is not published')
    }
  }, [template])

  return <ListItemText primary={primary} secondary={secondary} />
}

const DocumentTemplateList = () => {
  const classes = useStyles()

  const [isLoadingList, setIsLoadingList] = useState(false)
  const [documentTemplates, setDocumentTemplates] = useState([])

  const [filterText, setFilterText] = useState('')
  const [filteredAndSorted, setFilteredAndSorted] = useState([])
  const [filteredAndSortedArchived, setFilteredAndSortedArchived] = useState([])
  const [includeArchived, setIncludeArchived] = useState(false)

  useEffect(() => {
    setIsLoadingList(true)
    templateService.getDocumentTemplates().then(res => {
      setIsLoadingList(false)
      setDocumentTemplates(res)
    })
  }, [])

  useEffect(() => {
    var filteredAndSorted = documentTemplates
      .filter(
        db =>
          db.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
          filterText === ''
      )
      .sort((a, b) => a.name > b.name)
    setFilteredAndSorted(filteredAndSorted.filter(db => !db.isArchived))
    setFilteredAndSortedArchived(filteredAndSorted.filter(db => db.isArchived))
  }, [documentTemplates, filterText])

  return (
    <>
      <AdminBackdrop show={isLoadingList} />
      {!isLoadingList ? (
        <>
          <AdminPageHeader
            title={'Document Templates'}
            navActionLabel='Create New'
            navActionPath='/admin/template/add'
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AdminCard>
                <AdminCardHeader title={'Filter'} />
                <div style={{ marginBottom: 20 }}>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginRight: 10 }}>
                    <TextField
                      type='text'
                      label='Name'
                      value={filterText}
                      color='primary'
                      onChange={e => setFilterText(e.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginTop: 10 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includeArchived}
                          onChange={e => setIncludeArchived(e.target.checked)}
                          color='primary'
                        />
                      }
                      label='Include Archived?'
                    />
                  </FormControl>
                </div>
                <AdminCardHeader title={'Existing Templates'} />
                {!isLoadingList && includeArchived ? <h3>Current:</h3> : null}
                {!isLoadingList ? (
                  filteredAndSorted.length > 0 ? (
                    <List dense>
                      {filteredAndSorted.map(dt => (
                        <ListItem
                          key={dt.id}
                          button
                          component={Link}
                          to={`/admin/template/${dt.id}`}>
                          <TemplateListItemText template={dt} />
                          <ArrowForwardIcon className={classes.arrowIcon} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <p>No brandings</p>
                  )
                ) : null}
                {!isLoadingList && includeArchived ? <h3>Archived:</h3> : null}
                {!isLoadingList && includeArchived ? (
                  filteredAndSortedArchived.length > 0 ? (
                    <List dense>
                      {filteredAndSortedArchived.map(dt => (
                        <ListItem
                          key={dt.id}
                          button
                          component={Link}
                          to={`/admin/template/${dt.id}`}>
                          <TemplateListItemText template={dt} />
                          <ArrowForwardIcon className={classes.arrowIcon} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <p>No archived brandings</p>
                  )
                ) : null}
              </AdminCard>
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  )
}

export default DocumentTemplateList
