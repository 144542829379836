import React, { useState, useEffect, useRef } from 'react'
import { EditorState, Modifier } from 'draft-js'

import { makeStyles } from '@material-ui/core/styles'
import { generateHtmlFromEditorState } from '../utils/editorHelpers'
import { enqueueSnackbar } from '../actions/notification.actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  customLi: {
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  customUl: {
    width: 150,
  },
  dropdown: {
    minWidth: 260,
  },
})

const CustomEditorDropdrop = ({
  customTitle,
  customOptions,
  onChange,
  editorState,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  //OPTIONS FORMAT:
  // { id: 1, name: "Hi", value: "Hello"}

  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState([])

  const dropdownRef = useRef(null)

  const handleOutsideClick = e => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => setOptions(customOptions), [customOptions])

  const selectOption = selection => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      selection,
      editorState.getCurrentInlineStyle()
    )

    const editable = options.find(x => x.value === selection)
    const rawHtml = generateHtmlFromEditorState(editorState)
    if (rawHtml.includes(selection)) {
      dispatch(
        enqueueSnackbar({
          message: `${editable.name} already added to the document.`,
          options: {
            variant: 'warning',
          },
        })
      )
      return
    }

    onChange(EditorState.push(editorState, contentState, 'insert-characters'))
  }

  return (
    <div
      ref={dropdownRef}
      onClick={() => setIsOpen(!isOpen)}
      className='rdw-block-wrapper'
      aria-label='rdw-block-control'>
      <div
        className={`rdw-dropdown-wrapper rdw-block-dropdown ${classes.dropdown}`}
        aria-label='rdw-dropdown'>
        <div className='rdw-dropdown-selectedtext' title={customTitle}>
          <span>{customTitle}</span>
          <div
            className={`rdw-dropdown-caretto${
              isOpen ? 'close' : 'open'
            }`}></div>
        </div>
        {isOpen && (
          <ul
            className={`rdw-dropdown-optionwrapper ${classes.customUl} ${classes.dropdown}`}>
            {options
              .sort((a, b) => a.name > b.name)
              .map(o => (
                <li
                  className={`rdw-dropdownoption-default ${classes.customLi}`}
                  key={o.id}
                  onClick={() => selectOption(o.value)}>
                  {o.name}
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default CustomEditorDropdrop
