import React, { useEffect } from 'react'
import { Container, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as APLogo } from '../images/ap-logo.svg'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => {
  return {
    navWrapper: {
      position: 'sticky',
      top: 0,
      width: '100%',
      zIndex: 999,
    },
    mainNav: {
      backgroundColor: theme.palette.headerBackground.main,
      color: theme.palette.getContrastText(theme.palette.headerBackground.main),
      height: '128px',
      paddingBottom: '16px',
      paddingTop: '16px',
      transition: 'height 0.3s ease-out',
      display: 'flex',
    },
    subNav: {
      backgroundColor: theme.palette.navBackground.main,
      color: theme.palette.getContrastText(theme.palette.navBackground.main),
      minHeight: '48px',
      display: 'flex',
      alignItems: 'center',
    },
    subNavLinksContainer: {
      margin: 0,
      padding: 0,
    },
    subNavLeft: {
      marginRight: 'auto',
      '& button': {
        marginLeft: 0,
        marginRight: '32px',
      },
    },
    subNavRight: {
      marginLeft: 'auto',
      '& button': {
        marginRight: 0,
        marginLeft: '32px',
      },
    },
    greeting: {
      margin: 0,
      marginLeft: 'auto',
      fontWeight: 300,
      '& span': {
        fontWeight: 'bold',
      },
    },
    logo: {
      maxHeight: '100%',
      transition: '0.3s ease-out',
    },
  }
})

function NavBar({ leftSubNav, rightSubNav, progressPercentage }) {
  const classes = useStyles()

  const handlePageScroll = event => {
    const { scrollTop } = event.target.scrollingElement
    if (scrollTop > 128) {
      document.getElementById('main-nav').style.height = '48px'
      document.getElementById('ap-logo').style.visibility = 'hidden'
      document.getElementById('ap-logo').style.opacity = 0
    } else if (scrollTop < 48) {
      document.getElementById('main-nav').style.height = '128px'
      document.getElementById('ap-logo').style.visibility = 'visible'
      document.getElementById('ap-logo').style.opacity = 1
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', handlePageScroll)
    return () => {
      document.removeEventListener('scroll', handlePageScroll)
    }
  }, [])

  return (
    <header id='back-to-top-anchor' className={classes.navWrapper}>
      <div id='main-nav' className={classes.mainNav}>
        <Container maxWidth='lg' style={{ display: 'flex' }}>
          <APLogo
            id='ap-logo'
            alt='Adviser Plus logo'
            className={classes.logo}
          />
          <p className={classes.greeting}>
            Welcome to <span>ER Support</span>
          </p>
        </Container>
      </div>
      {(leftSubNav || rightSubNav) && (
        <div className={classes.subNav}>
          <Container maxWidth='lg' style={{ display: 'flex' }}>
            {leftSubNav && (
              <div
                className={`${classes.subNavLinksContainer} ${classes.subNavLeft}`}>
                {leftSubNav()}
              </div>
            )}
            {rightSubNav && (
              <div
                className={`${classes.subNavLinksContainer} ${classes.subNavRight}`}>
                {rightSubNav()}
              </div>
            )}
          </Container>
        </div>
      )}
      {!!progressPercentage && (
        <LinearProgress variant='determinate' value={progressPercentage} />
      )}
    </header>
  )
}

NavBar.propTypes = {
  leftSubNav: PropTypes.func,
  rightSubNav: PropTypes.func,
  progressPercentage: PropTypes.number,
}

export default NavBar
